<template>
  <data-table v-bind:scope="scope"
              dataTableOptions="favouriteApartmentsGrid"
              queryObj="favouriteObjects"
              v-bind:orderObj="orderObj"></data-table>
</template>

<script>
import DataTableBase from '@/components/DataTableBase.vue';

const ORDER_OBJECT = {
  field: 'planViewingAt',
  direction: 'desc'
};

export default {
  extends: DataTableBase,
  computed: {
    orderObj() {
      return ORDER_OBJECT;
    }
  },
  mounted() {
    this.$store.commit('changeScope', 'visits');
    this.$store.commit('changeData', true);
  }
};
</script>